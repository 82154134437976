@font-face {
  font-family: "SLIMEDUNK";
  src: url("/public/fonts/SLIMEDUNK.otf") format("opentype");
}
@font-face {
  font-family: "grizzlies";
  src: url("/public/fonts/nba-grizzlies.otf") format("opentype");
}

@font-face {
  font-family: "futura";
  src: url("/public/fonts/futura.otf") format("opentype");
}

@font-face {
  font-family: "base";
  src: url("/public/fonts/nba-lakers.woff") format("woff");
}
@font-face {
  font-family: "Brewheat";
  src: url("/public/fonts/Brewheat.woff2") format("woff2"),
    /* Modern Browsers */ url("/public/fonts/Brewheat.otf") format("opentype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

header {
  font-family: "futura", sans-serif; /* Use the font family you specified */
  /* Additional styles for your header */
}
/* Team Colors */
.team5 {
  /* Grizz */
  background: linear-gradient(#5d76a9, #12173f);
}
.team1-name {
  font-family: "brewheat";
}
.team1-record {
  font-family: "brewheat";
}
.team4 {
  /* Suns */
  background: linear-gradient(#e56020, #2b016e);
  color: #fff;
}
.team1 {
  /* Lakers */
  background: linear-gradient(#fdb927, #552583);
  color: #fff;
}
.team2 {
  /* Jazz */
  background: linear-gradient(#00471b, #002b5c);
  color: #fff;
}
.team3 {
  /* Blazers */
  background: linear-gradient(#e03a3e, #000000);
  color: #fff;
}
.team6 {
  /* Denver */
  background: linear-gradient(#1d428a, #0e2240);
  color: #fff;
}
.team7 {
  /* Clippers */
  background: linear-gradient(#c8102e, #1d428a);
  color: #fff;
}
.team8 {
  /* Pelicans */
  background: linear-gradient(#85714d, #0c2340);
  color: #fff;
}
.team9 {
  /* Mavs */
  background: linear-gradient(#00538c, #002b5e);
  color: #fff;
}
.team10 {
  /* Rockets */
  background: linear-gradient(#ce1141, #041e42);
  color: #fff;
}
.team11 {
  /* Kings */
  background: linear-gradient(#63727a, #5a2d81);
  color: #fff;
}
.team12 {
  /* Warriors */
  background: linear-gradient(#ffc72c, #1d428a);
  color: #fff;
}
.team13 {
  /* Wolves */
  background: linear-gradient(#236192, #0c2340);
  color: #fff;
}
.team14 {
  /* OKC */
  background: linear-gradient(#007ac1, #ef3b24);
  color: #fff;
}
.team15 {
  /* Spurs */
  background: linear-gradient(#00b2a9, #ef426f);
  color: #000;
}
.team16 {
  /* Sixers */
  background: linear-gradient(#ed174c, #006bb6);
  color: white;
}

.team17 {
  /* Cavs */
  background: linear-gradient(#860038, #000000);
  color: white;
}

.team18 {
  /* Hawks */
  background: linear-gradient(#ffffff, #c8102e);
  color: white;
}

.team19 {
  /* Pacers */
  background: linear-gradient(#fdbb30, #002d62);
  color: white;
}

.team20 {
  /* Pistons */
  background: linear-gradient(#c8102e, #1d42ba);
  color: white;
}

.team21 {
  /* Bucks */
  background: linear-gradient(#eee1c6, #00471b);
  color: #fff;
}
.team22 {
  /* Knicks */
  background: linear-gradient(#f58426, #006bb6);
  color: #fff;
}
.team23 {
  /* Heat */
  background: linear-gradient(#f9a01b, #98002e);
  color: #fff;
}
.team24 {
  /* Wizards */
  background: linear-gradient(#e31837, #002b5c);
  color: #fff;
}
.team24.expanded-row {
  background-image: url("/public/courts/wizards.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.team25 {
  /* Hornets */
  background: linear-gradient(#00788c, #1d1160);
  color: #fff;
}

.team26 {
  /* Celtics */
  background: linear-gradient(#ffffff, #007a33);
  color: #fff;
}
.team27 {
  /* Nets */
  background: linear-gradient(#ffffff, #000000);
  color: #fff;
}
.team28 {
  /* Raptors */
  background: linear-gradient(#ce1141, #753bbd);
  color: #fff;
}
.team29 {
  /* Bulls */
  background: linear-gradient(#ce1141, #000000);
  color: #fff;
}
.team30 {
  /* Magic */
  background: linear-gradient(#0077c0, #000000);
  color: #fff;
}
/* Add more styles for additional teams as needed */
html,
body {
  margin: 0;
}
body {
  background: linear-gradient(to bottom, #1a1a1a, #000000);
  background-repeat: no-repeat;
}

.logo-image {
  width: 500px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-top: 100px;
  margin-bottom: 50px; /* Adjust the margin as needed */
  /* Add more styles as per your design requirements */
}

.group-title {
  /* Your styles for group titles go here */
  font-size: 5em;
  font-weight: bold;
  background-image: linear-gradient(
    to bottom,
    #ebd197,
    #a2790d
  ); /* Adjust the color as needed */
  background-clip: text;
  color: transparent;
  margin-bottom: 30px;

  filter: drop-shadow(
    6px 6px var(--shadow),
    5.75px 5.75px var(--shadow),
    5.5px 5.5px var(--shadow),
    5.25px 5.25px var(--shadow),
    5px 5px var(--shadow),
    4.75px 4.75px var(--shadow),
    4.5px 4.5px var(--shadow),
    4.25px 4.25px var(--shadow),
    4px 4px var(--shadow),
    3.75px 3.75px var(--shadow),
    3.5px 3.5px var(--shadow),
    3.25px 3.25px var(--shadow),
    3px 3px var(--shadow),
    2.75px 2.75px var(--shadow),
    2.5px 2.5px var(--shadow),
    2.25px 2.25px var(--shadow),
    2px 2px var(--shadow),
    1.75px 1.75px var(--shadow),
    1.5px 1.5px var(--shadow),
    1.25px 1.25px var(--shadow),
    1px 1px var(--shadow),
    0.75px 0.75px var(--shadow),
    0.5px 0.5px var(--shadow),
    0.25px 0.25px var(--shadow)
  );
}

.subgroup-title {
  /* Your styles for subgroup titles go here */
  font-size: 2.5em;
  font-weight: bold;
  background-image: linear-gradient(
    to bottom,
    #ebd197,
    #a2790d
  ); /* Adjust the color as needed */
  background-clip: text;
  color: transparent;
  margin-bottom: 5px;
  filter: drop-shadow(3px 3px #333); /* Adjust the values as needed */
}

/* Common Team Row Styles */
.main-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */
}

.groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "brewheat";
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */
}

.team-list {
  list-style: none;
  padding: 0;
}
/* Common Team Row Styles */

.groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.group-container {
  width: 48%;
  margin-bottom: 20px;
}

.team-list {
  list-style: none;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

/* Common Team Row Styles */
.team-row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 100px;
  padding: 8px;
  max-width: 100%;
  max-height: 100%; /* Ensure the row doesn't exceed its container width */
  overflow: clip; /* Prevent elements from breaking out */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8) !important;
  margin-bottom: 1px;

  /* Add these flex properties for positioning */
  justify-content: center;
  position: relative; /* Logo to the left, Record to the right */
}

/* Expanded Row Styles */
.expanded-row {
  background-image: var(--team-background-image);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 190px; /* Adjust the height to your preference */
  display: flex;
  align-items: flex-end;
  position: static; /* Center the content vertically */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9) !important;
  box-sizing: content-box; /* Adjust the values as needed */
  margin-bottom: 9px; /* Adjust the values as needed */
  justify-content: space-between, center;
}

a {
  color: white;
}
a:link {
  color: white;
}
a:visited {
  color: white;
}

.info-box {
  width: 90px; /* Adjust the width of each box */
  padding: 8px;
  background-color: rgba(255, 255, 255, 0);
  position: static;
  font-family: "futura";
  font-weight: bold;
  color: white;
  font-size: 17px;
}

/* Optionally, add specific styles for each box */

.team-logo {
  max-width: 120;
  height: auto;
  flex-shrink: 1;
  overflow: hidden; /* Prevent elements from breaking out */

  position: absolute;
  left: 0; /* Position the image to the left of the div */
  top: 50%; /* Position the image vertically in the middle */
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
  /* Apply the dimming effect using CSS filter */
  filter: brightness(0.8);
}

.team-details {
  display: relative;
  align-items: center;
}

:root {
  --shadow: rgba(0, 0, 0, 1);
}
.team-name {
  font-weight: bold;
  font-size: 3rem;
  position: relative;
  margin: 0; /* Remove margin to center vertically */
  -webkit-text-stroke: 0px black; /* Add a black border */
  color: white; /* Text color with a white fallback */
}

.team-record {
  font-weight: bold;
  font-size: 4rem;
  position: relative;
  -webkit-text-stroke: 0px black; /* Add a black border */
  color: whitesmoke; /* Text color with a white fallback */
  text-shadow: 6px 6px var(--shadow), 5.75px 5.75px var(--shadow),
    5.5px 5.5px var(--shadow), 5.25px 5.25px var(--shadow),
    5px 5px var(--shadow), 4.75px 4.75px var(--shadow),
    4.5px 4.5px var(--shadow), 4.25px 4.25px var(--shadow),
    4px 4px var(--shadow), 3.75px 3.75px var(--shadow),
    3.5px 3.5px var(--shadow), 3.25px 3.25px var(--shadow),
    3px 3px var(--shadow), 2.75px 2.75px var(--shadow),
    2.5px 2.5px var(--shadow), 2.25px 2.25px var(--shadow),
    2px 2px var(--shadow), 1.75px 1.75px var(--shadow),
    1.5px 1.5px var(--shadow), 1.25px 1.25px var(--shadow),
    1px 1px var(--shadow), 0.75px 0.75px var(--shadow),
    0.5px 0.5px var(--shadow), 0.25px 0.25px var(--shadow);
}

#canvas-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive design - adjust as needed */
/* Styles for mobile-sized screens */

@media only screen and (min-width: 600px) and (max-width: 950px) {
  .main-container {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
  }

  .group-container {
    width: 50%;
  }

  .groups {
    width: 100%;
  }

  .info-box {
    width: 75px; /* Adjust the width of each box */
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    position: static;
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .main-container {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
  }

  .group-container {
    width: 100%;
  }

  .groups {
    width: 100%;
  }

  .team-row {
    position: relative;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }

  .team-name {
    width: 100%;
    font-size: 1.1rem; /* Adjust the font size as needed for smaller screens */
  }

  .team-record {
    width: 100%;
    font-size: 1.9rem; /* Adjust the font size as needed for smaller screens */
  }

  .team-logo::after {
    max-width: 100%; /* Ensure the logo doesn't exceed the container width */
    background-color: rgba(0, 0, 0, 0.3);
  }

  .expanded-row {
    background-color: transparent, rgba(0, 0, 0, 0);
    height: 200px; /* Make the expanded row background transparent */
    margin-bottom: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
    position: static;
  }

  .info-box {
    width: 55px; /* Adjust the width of each box */
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.8);
    position: static;
    font-size: 0.8rem;
  }

  .team-name,
  .team-record {
    color: white; /* Reset the text color to white */
  }
}

@media only screen and (min-width: 400px) and (max-width: 540px) {
  .main-container {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
  }

  .group-container {
    width: 100%;
  }

  .groups {
    width: 100%;
  }

  .team-row {
    position: relative;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }

  .team-name {
    width: 100%;
    font-size: 1.1rem; /* Adjust the font size as needed for smaller screens */
  }

  .team-record {
    width: 100%;
    font-size: 2.4rem; /* Adjust the font size as needed for smaller screens */
  }
  .team-list {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 35px;
  }

  .team-logo::after {
    max-width: 100%; /* Ensure the logo doesn't exceed the container width */
    background-color: rgba(0, 0, 0, 0.3);
  }

  .expanded-row {
    background-color: transparent, rgba(0, 0, 0, 0);
    height: 145px; /* Make the expanded row background transparent */
    margin-bottom: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
    position: static;
  }

  .info-box {
    width: 75px; /* Adjust the width of each box */
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.8);
    position: static;
    font-size: 0.8rem;
  }

  .team-name,
  .team-record {
    color: white; /* Reset the text color to white */
  }
  .logo-image {
    width: 500px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    padding-top: 30px;
    margin-bottom: 50px; /* Adjust the margin as needed */
    /* Add more styles as per your design requirements */
  }
  .group-title {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
  .subgroup-title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 360px) and (max-width: 395px) {
  .main-container {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
    padding-top: 0px;
  }

  .group-title {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
  .subgroup-title {
    font-size: 2rem;
  }
  .group-container {
    width: 100%;
  }

  .groups {
    width: 100%;
  }

  .team-row {
    position: relative;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }

  .team-name {
    width: 100%;
    font-size: 1.1rem; /* Adjust the font size as needed for smaller screens */
  }
  .team-list {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .team-record {
    width: 100%;
    font-size: 1.9rem; /* Adjust the font size as needed for smaller screens */
  }

  .team-list {
    margin: 5px;
  }

  .team-logo::after {
    max-width: 100%; /* Ensure the logo doesn't exceed the container width */
    background-color: rgba(0, 0, 0, 0.3);
  }

  .expanded-row {
    background-color: transparent, rgba(0, 0, 0, 0);
    height: 130px; /* Make the expanded row background transparent */
    margin-bottom: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
    position: static;
  }

  .info-box {
    width: 65px; /* Adjust the width of each box */
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.8);
    position: static;
    font-size: 0.7rem;
  }

  .team-name,
  .team-record {
    color: white; /* Reset the text color to white */
  }
  .logo-image {
    width: 500px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    padding-top: 30px;
    margin-bottom: 50px; /* Adjust the margin as needed */
    /* Add more styles as per your design requirements */
  }
}

@media only screen and (min-width: 300px) and (max-width: 350px) {
  .main-container {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    position: relative;
  }

  .group-container {
    width: 100%;
  }

  .groups {
    width: 100%;
  }

  .team-row {
    position: relative;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }

  .team-name {
    width: 100%;
    font-size: 1.1rem; /* Adjust the font size as needed for smaller screens */
  }

  .team-record {
    width: 100%;
    font-size: 1.9rem; /* Adjust the font size as needed for smaller screens */
  }

  .team-list {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .team-logo::after {
    max-width: 100%; /* Ensure the logo doesn't exceed the container width */
    background-color: rgba(0, 0, 0, 0.3);
  }

  .expanded-row {
    background-color: transparent, rgba(0, 0, 0, 0);
    height: 130px; /* Make the expanded row background transparent */
    margin-bottom: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
    position: static;
  }

  .info-box {
    width: 65px; /* Adjust the width of each box */
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.8);
    position: static;
    font-size: 0.7rem;
  }

  .team-name,
  .team-record {
    color: white; /* Reset the text color to white */
  }
  .logo-image {
    width: 500px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    padding-top: 30px;
    margin-bottom: 50px; /* Adjust the margin as needed */
    /* Add more styles as per your design requirements */
  }
  .group-title {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
  .subgroup-title {
    font-size: 2rem;
  }
}
