.logo-image {
  width: 500px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-top: 50px;
  margin-bottom: 20px; /* Adjust the margin as needed */
  /* Add more styles as per your design requirements */
}

html,
body {
  margin: 0;
}
body {
  background: linear-gradient(to bottom, #1a1a1a, #000000);
  background-repeat: no-repeat;
}

.statistics-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height instead of height */
  margin-top: -150px;
  padding-left: 80px;
  padding-right: 80px;
}

/* Assuming you are using a wrapper for your table with class .table-container */
.table-container {
  width: 100%;
  overflow-x: auto; /* or overflow: hidden; */
  max-width: 1400px; /* Adjust the max-width as needed */
  margin: 0px auto; /* Center the table */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 1em; /* Set the desired font color */
}

.MuiDataGrid-root {
  width: 100%; /* or adjust the width as needed */
}

/* Adjust the font color for cells, column headers, and other elements */
.MuiDataGrid-root .MuiDataGrid-cell,
.MuiDataGrid-root .MuiDataGrid-colCell,
.MuiDataGrid-root .MuiDataGrid-colCellTitle,
.MuiDataGrid-root .MuiDataGrid-columnHeaders,
.MuiDataGrid-root .MuiDataGrid-footerContainer,
.MuiDataGrid-root .MuiToolbar-root,
.css-1xu9g6-MuiTableCell-root {
  font-size: 2px; /* Adjust the font size as needed */
}

/* Center the text in column headers */
.MRT_TableHeadCell {
  text-align: center;
}

/* Adjust the font size and other styles for smaller screens */

@media only screen and (max-width: 1366px) {
  /* Add any additional mobile-specific styles here */
  .statistics-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Use min-height instead of height */
    margin-top: -40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  /* Add any additional mobile-specific styles here */
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }

  .MRT_TableBodyCell,
  .MRT_TableHeadCell,
  .MRT_TablePagination,
  .MRT_TableFooterCell {
    font-size: 8px; /* Set the desired font size for mobile */
  }

  .logo-image {
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-top: 20px;
    margin-bottom: 20px; /* Adjust the margin as needed */
    /* Add more styles as per your design requirements */
  }
  .statistics-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Use min-height instead of height */
    margin-top: -100px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 375px) {
  .statistics-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Use min-height instead of height */
    margin-top: -10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
